<template>
  <div class="pageContol listWrap templateList addClassCtr">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;" @click="$router.back()">班级列表</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">创建班级</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-form
              class="el-form-liveClass"
              ref="Form"
              :model="Form"
              :rules="rules"
              label-width="130px"
            >
              <el-form-item
                label="班级名称："
                prop="liveProjectName"
                class="form-item"
              >
                <el-input
                  v-model="Form.liveProjectName"
                  placeholder="请输入班级名称"
                  :disabled="disabled"
                  maxlength="50"
                  show-word-limit
                  size="small"
                />
              </el-form-item>
              <el-form-item label="行政区划：" prop="areaId" class="form-item">
                <el-cascader
                  v-model="Form.areaId"
                  clearable
                  filterable
                  :options="areatreeList"
                  :props="propsarea"
                  :disabled="disabled"
                  size="small"
                ></el-cascader>
              </el-form-item>
              <el-form-item
                label="有效日期："
                prop="Effectivedate"
                class="form-item"
              >
                <el-date-picker
                  size="small"
                  v-model="Form.Effectivedate"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                ></el-date-picker>
              </el-form-item>
              <el-form-item
                label="所属期次："
                prop="periodId"
                class="form-item form-period"
              >
                <el-select
                  v-model="Form.periodId"
                  placeholder="请选择所属期次"
                  size="small"
                >
                  <el-option
                    v-for="item in periodList"
                    :key="item.periodId"
                    :label="item.periodName"
                    :value="item.periodId"
                  >
                  </el-option>
                </el-select>
                <span style="margin-left: 10px;">没有期次?</span>
                <el-button
                  size="small"
                  type="text"
                  style="margin-left: 10px;font-size:.85rem"
                  @click="addperiod()"
                  >点击新建期次</el-button
                >
              </el-form-item>
              <el-form-item
                label="负责人："
                prop="projectUser"
                class="form-item"
              >
                <el-input
                  v-model="Form.projectUser"
                  placeholder="请输入负责人"
                  :disabled="disabled"
                  size="small"
                />
              </el-form-item>
              <el-form-item
                label="负责人电话："
                prop="projectUserPhone"
                class="form-item"
              >
                <el-input
                  v-model="Form.projectUserPhone"
                  placeholder="请输入负责人电话"
                  maxlength="12"
                  :disabled="disabled"
                  size="small"
                />
              </el-form-item>
              <el-form-item
                label="培训类型："
                prop="Trainingtype"
                class="form-item"
              >
                <tree
                  ref="tree"
                  @eventBtn="childBack"
                  :ruleForm="Form"
                  size="small"
                  @clearParams="clearParams"
                  modal
                  type
                  typeStu
                />
              </el-form-item>
              <el-form-item
                label="培训人数："
                prop="projectPeople"
                class="form-item"
                required
              >
                <el-input-number
                  v-model="Form.projectPeople"
                  @change="handleChange"
                  :min="1"
                  :max="999999999"
                  style="width:100%"
                  :disabled="disabled"
                  size="small"
                ></el-input-number>
              </el-form-item>
            </el-form>
            <div class="bottom-btn">
              <el-button @click="cancle" class="bgc-bv">取消</el-button>
              <el-button @click="seveData()" class="bgc-bv">保存</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 期次弹窗 -->
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="55%"
      top="3%"
      :center="true"
      :before-close="doClose"
      :close-on-click-modal="false"
    >
      <div class="ovy-a">
        <el-form
          label-width="80px"
          ref="FormPeriod"
          :model="FormPeriod"
          :rules="rulesPeriod"
        >
          <el-form-item label="期次名称" prop="periodName">
            <el-input
              v-model="FormPeriod.periodName"
              @keyup.native="FormPeriod.periodName = zF.stringRemoveLeftSpace(FormPeriod.periodName)"
              type="text"
              size="small"
              placeholder="请输入期次名称"
            />
          </el-form-item>
          <el-form-item label="培训人数" prop="trainNum">
            <el-input-number
              v-model="FormPeriod.trainNum"
              @change="handleChange"
              :min="1"
              style="width:100%"
              size="small"
            ></el-input-number>
          </el-form-item>
        </el-form>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
        style="display: flex; justify-content: center;"
      >
        <el-button class="bgc-bv" @click="doClose('FormPeriod')"
          >取消</el-button
        >
        <el-button class="bgc-bv" @click="getSure('FormPeriod')"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import UploadPic from "@/mixins/UploadPic";
import DatePicker from "@/mixins/DatePicker";
import tree from "@/components/treePopup";
import { mapGetters } from "vuex";
export default {
  name: "addLiveClass",
  components: {
    tree,
  },
  mixins: [UploadPic, DatePicker],
  data() {
    var projectUserPhone = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入正确的负责人电话"));
      } else if (
        value &&
        !/^1[3456789]\d{9}$/.test(value) &&
        !/^([0-9]{3,4}-)?[0-9]{7,8}$/.test(value)
      ) {
        callback(new Error("请输入正确的负责人电话"));
      } else {
        callback();
      }
    };

    return {
      disabled: false,
      paperId: false,
      areatreeList: [],
      props: {
        value: "id",
        label: "label",
      },
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
      Form: {
        projectId: "",
        liveProjectName: "",
        areaId: "",
        Effectivedate: [],
        periodId: "",
        projectUser: "",
        projectUserPhone: "",
        projectPeople: "",
        Trainingtype: "",
      },
      params: {},
      rules: {
        liveProjectName: [
          { required: true, message: "请输入班级名称", trigger: "blur" },
        ],
        areaId: [
          { required: true, message: "请选择行政区划", trigger: "change" },
        ],
        Effectivedate: [
          { required: true, message: "请选择有效时间", trigger: "change" },
        ],
        periodId: [
          { required: true, message: "请选择所属期次", trigger: "change" },
        ],
        projectUser: [
          { required: true, message: "请输入负责人", trigger: "blur" },
        ],
        projectUserPhone: [
          { required: true, validator: projectUserPhone, trigger: "blur" },
        ],
        Trainingtype: [
          { required: true, message: "请选择培训类型", trigger: "change" },
        ],
      },
      /* 期次弹窗 */
      dialogVisible: false,
      dialogTitle: "新建期次",
      FormPeriod: {
        periodName: "",
        trainNum: "",
      },
      rulesPeriod: {
        periodName: [
          { required: true, message: "请输入期次名称", trigger: "blur" },
        ],
        trainNum: [
          { required: true, message: "请输入培训人数", trigger: "blur" },
        ],
      },
      periodList: [],
    };
  },
  created() {
    this.getareatree();
    this.getSelectPeriod();
  },
  methods: {
    childBack(params) {
      this.params = { ...params };
      this.selectType = this.params.trainFormIdPath;
    },
    clearParams() {
      this.params = {};
    },
    getareatree() {
      this.$post("/sys/area/tree").then((ret) => {
        this.areatreeList = ret.data;
      });
    },
    getSelectPeriod() {
      this.$post("/biz/live/period/getPeriodList", {}, 3000, true, 3)
        .then((ret) => {
          this.periodList = ret.data;
        })
        .catch((err) => {
          return;
        });
    },
    seveData() {
      const el = this.$refs.Form;
      el.validate((valid) => {
        if (valid) {
          this.doAjax();
        }
      });
    },
    doAjax() {
      let parmar = {
        liveProjectName: this.Form.liveProjectName,
        areaId: this.Form.areaId,
        startDate: this.Form.Effectivedate[0],
        endDate: this.Form.Effectivedate[1],
        signEndDate: this.Form.deadline,
        projectUser: this.Form.projectUser,
        projectUserPhone: this.Form.projectUserPhone,
        projectPeople: this.Form.projectPeople,
        periodId: this.Form.periodId,
      };
      if (this.Form.liveProjectId) {
        parmar.liveProjectId = this.Form.liveProjectId;
      }
      if (this.params.trainFormId) {
        parmar.trainTypeId = this.params.trainFormId;
      }
      if (this.params.levelFormId) {
        parmar.trainLevelId = this.params.levelFormId;
      }
      if (this.params.occFormId) {
        parmar.occupationId = this.params.occFormId;
      }
      if (this.params.postFormId) {
        parmar.postId = this.params.postFormId;
      }
      if (this.params.industryFormId) {
        parmar.industryId = this.params.industryFormId;
      }
      this.$post("/liveProject/live-project/insert", parmar, 3000, true, 3)
        .then((ret) => {
          if (ret.status == 0) {
            this.$message({
              message: ret.message,
              type: "success",
            });
            this.$router.push({
              name: 'liveClassManagement',
              params: {
                refresh: true,
                isAdd: true,
              },
            });
          }
        })
        .catch((err) => {
          return;
        });
    },
    cancle() {
      this.$router.back();
    },
    /* 新建期次 */
    addperiod() {
      this.dialogVisible = true;
    },
    doClose() {
      this.dialogVisible = false;
      this.$refs["FormPeriod"].resetFields();
      this.FormPeriod = {
        periodName: "",
        periodtraineesNum: "",
      };
    },
    getSure(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.doeAjax();
        }
      });
    },
    doeAjax() {
      const parmar = {
        periodName: this.FormPeriod.periodName,
        trainNum: this.FormPeriod.trainNum,
      };
      this.$post("/biz/live/period/insert", parmar, 3000, true, 3)
        .then((ret) => {
          if (ret.status == 0) {
            this.$message({
              message: ret.message,
              type: "success",
            });
            let periodData = ret.data;
            this.doClose();
            this.getSelectPeriod();
            this.Form.periodId = periodData.periodId;
          }
        })
        .catch((err) => {
          return;
        });
    },
  },
};
</script>
<style lang="less"></style>
<style lang="less">
.addClassCtr {
  .el-form-liveClass {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .form-item {
      width: 45%;
    }
  }
  .bottom-btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .form-period {
    .el-form-item__content {
      display: flex;
      align-items: center;
      .el-select {
        flex: 1;
      }
    }
  }
}
</style>
